@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Afacad:ital,wght@0,400..700;1,400..700&display=swap");

html {
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.res-nav span {
  position: unset;
}

.demo-class div + div {
  background-color: transparent;
  border-radius: 9999px;
  box-shadow: none;
}
.custom-carousel .thumbs-wrapper .thumb {
  height: 80px;
  width: 120px;
  object-fit: cover;
  border-radius: 8px;
}

.custom-carousel .thumbs-wrapper .thumb img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove the spinner for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Optional: Customize the input style */
input[type="number"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

html {
  overflow: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

.slick-track {
  display: flex !important;
  gap: 10px;
}

.carousel .thumbs-wrapper {
  margin: 20px 0 !important;
}

.top-slider button + div div {
  gap: 0 !important;
}
.top-slider {
  -webkit-user-drag: element; /* For Chrome/Safari */
  -moz-user-drag: element; /* For Firefox */
}
.prop-slider button + div div {
  margin-left: 1px !important;
  margin-right: unset !important;
}
/* .lookup-slider button + div div {
  height: auto !important;
} */

.description-count ul {
  list-style-type: unset;
  padding: revert;
}
.description-count ol {
  list-style: decimal;
  padding: revert;
}

.app .block {
  display: block !important;
}

.seo-head-scroll nav {
  overflow-x: scroll;
  white-space: nowrap;
}
.seo-head-scroll nav::-webkit-scrollbar {
  width: 2px; /* Width of the scrollbar */
}

.seo-head-scroll nav::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the track */
}

.seo-head-scroll nav::-webkit-scrollbar-thumb {
  background: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners of the thumb */
}

.seo-head-scroll nav::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color of the thumb on hover */
}

.inner-html-custom h1 {
  font-size: 2.5rem;
  font-weight: bold;
  /* margin: 20px 0; */
}
.inner-html-custom h2 {
  font-size: 2rem;
  font-weight: bold;
  /* margin: 20px 0; */
}
.inner-html-custom h3 {
  font-size: 1.75rem;
  font-weight: bold;
  /* margin: 20px 0; */
}
.inner-html-custom h4 {
  font-size: 1.5rem;
  font-weight: bold;
  /* margin: 20px 0; */
}
.inner-html-custom h5 {
  font-size: 1.25rem;
  font-weight: bold;
  /* margin: 20px 0; */
}
.inner-html-custom h6 {
  font-size: 1rem;
  font-weight: bold;
  /* margin: 20px 0; */
}
.inner-html-custom p {
  font-size: 1rem;
  /* font-weight: bold; */
  /* margin: 20px 0; */
}

.custom-input-area div {
  min-width: unset;
}

@media (max-width: 540px) {
  .slick-slider {
    display: none !important;
  }
  .my-slider {
    display: block !important;
  }
  .swiper-wrapper {
    width: 75% !important;
  }
  .swiper-slide-visible {
    width: 120%;
  }
}
@media (max-width: 480px) {
  .card-size {
    height: 160px !important;
  }
  .slick-track {
    display: flex !important;
    gap: 0px;
  }
}
